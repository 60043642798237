@import 'src/utils/utils';

.checkbox-select {
  @include zero;
  display: grid;
  row-gap: 15px;
  margin-right: 16px;
  margin-left: 16px;
  margin-bottom: 20px;

  @media(min-width: $lg) {
    grid-template-columns: 280px 280px;
    column-gap: 15px;
    row-gap: 0;
    margin-right: 0;
    margin-left: 0;
  }
  @media(min-width: $xxl) {
    @include box;
  }

  &__container {
    @include zero;
    display: grid;
    row-gap: 5px;
  }

  &__header {
    @include zero;
    @include Text-12-reg;
    color: $Main-1;
  }
}

.select2-selection__clear {
  display: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: none;
}

.select2-container--default .select2-search--inline .select2-search__field {
  width: unset !important;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 0;
}

.select2-container--default .select2-selection--multiple {
  border: 0;
  padding-top: 7px;
  padding-right: 10px;
  padding-bottom: 7px;
  padding-left: 14px;
  border-radius: 8px !important;
}

.select2-container .select2-search--inline .select2-search__field {
  margin: 0;
  height: 20px;

  &::placeholder {
    @include Text-14-reg;
    color: $Main-1;
  }
}

.select2-container--open .select2-dropdown {
  @include Shadow-1;
  top: 10px;
}

.select2-container--open .select2-dropdown--below {
  border-radius: 8px !important;
  border: 0;
}

.select2-results__option {
  @include Text-16-reg;
  position: relative;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 48px;
  padding-right: 14px;
  color: $Main-1;

  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 14px;
    margin: auto;
    width: 24px;
    height: 24px;
    border-radius: 8px;
    border: 1px solid $Main-5;
    content: '';
  }

  &:after {
    // position: absolute;
    // top: 12px;
    // left: 20px;
    // width: 12px;
    // height: 6px;
    // border-top: 1px solid $Main-2;
    // border-right: 1px solid $Main-2;
    // transform: rotate(135deg);
    // visibility: hidden;
    // content: "";

    position: absolute;
    top: 0;
    bottom: 0;
    left: 20px;
    margin: auto;
    width: 12px;
    height: 6px;
    border-top: 1px solid $Main-2;
    border-right: 1px solid $Main-2;
    transform: rotate(135deg) translateX(-2px) translateY(1px);
    visibility: hidden;
    content: "";

    // @media(min-width: $lg) {
    //   top: 15px;
    // }
  }
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: transparent;
  color: $Main-1;
}

.select2-container--default .select2-results__option--selected {
  background-color: transparent;

  &:before {
    border-color: $Corporate-2;
    background-color: $Corporate-2;
  }

  &:after {
    visibility: visible;
  }
}

.select2-container {
  transition: box-shadow 0.3s;

  &--open {
    @include Shadow-1;
  }
}